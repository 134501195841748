var addToCardProducts = [];

$(document).ready(function()
{
    $(document).on('click', '.js-am-add-to-cart', function(evt)
    {
        evt.preventDefault();
        sendAddToCart($(this));
    });

    prestashop.on('updateCart', function (event)
    {

        var responseData = {};

        if (event && event.reason && event.reason.cart) {
            responseData = {
                id_product: event.reason.id_product,
                id_attribute: event.reason.id_attribute,
                id_product_attribute: event.reason.id_product_attribute,
                qty: event.reason.quantity,
                products_in_cart: event.reason.cart.products
            };
            updateProductItem(responseData);
        }

    });
    prestashop.on('updateAllProducts', function(event)
    {
        if (event && event.reason) {
            updateAllProductsInCart(event.reason.productsQuantity);}

    });


});

function amDisplayAddToCartButton($itemProduct, resp)
{
    var quantity = resp.quantity;
    var idProductAttribute = resp.id_product_attribute;
    var $addToCartbutton = $itemProduct.find('.addButton');
    var $quantityButton = $itemProduct.find('.addQuantity');

    if(quantity > 0) {
        $addToCartbutton.hide();
        $quantityButton.show();
        $quantityButton.find('span.am-js-quantity').text(quantity);
    }
    else {
        $addToCartbutton.show();
        $quantityButton.hide();
    }
}

function sendAddToCart($button)
{

    var $itemProduct = $button.closest('.js-product-miniature');
    var $addToCartData = $('.am-addToCartData');
    var url = $addToCartData.data('url');
    var idProduct = $itemProduct.data('id-product');
    var numberAttributeGroups = $itemProduct.find('.am-js-attribute-group').length;
    var idProductAttribute = $addToCartData.data('id-product-attribute');
    var quantityDirection = '';
    var dataToSend = {};

    if( (numberAttributeGroups>0) && (idProductAttribute > 0))
        return false;

    if ($button.hasClass('js-am-qty-increase'))
        quantityDirection = 'up';
    else  if ($button.hasClass('js-am-qty-decrease'))
        quantityDirection = 'down';

    if(quantityDirection != '') {
        dataToSend = {
            ajax:1,
            add:1,
            action:'update',
            id_product: idProduct,
            id_product_attribute: idProductAttribute,
            op: quantityDirection
        };
    }
    else {
        dataToSend = {
            ajax:1,
            add:1,
            action:'update',
            id_product: idProduct,
            id_product_attribute: idProductAttribute
        };
    }
    var addToCart = $.ajax({
        url: url,
        type: 'POST',
        data: dataToSend
    });

    addToCart.done(function(resp)
    {
        resp = $.parseJSON(resp);
        if(resp.success){
            amDisplayAddToCartButton($itemProduct, resp);
            prestashop.emit('updateCart', {
                reason: {
                    idProduct: resp.id_product,
                    idProductAttribute: resp.id_product_attribute,
                    linkAction: 'add-to-cart',
                    cart: resp.cart
                },
                resp: resp
            });
        }
        else if (resp.hasError) {
            txtErrors = '';
            $.each(resp.errors, function(index, value) {
                txtErrors += "\n"+value;
            });

            if(txtErrors != '')
                $itemProduct.find('.js-am-variants-notifications')
                    .removeClass('alert-danger alert-success alert-info')
                    .addClass('alert-danger')
                    .html(txtErrors)
                ;
        }
        else{
            // console.log('else void');
        }
    });

    addToCart.fail(function(xhr, status)
    {
        prestashop.emit('handleError', {eventType: 'updateShoppingCart', resp: xhr});

        $itemProduct.find('.js-am-variants-notifications')
            .removeClass('alert-danger alert-success alert-info')
            .addClass('alert-danger')
            .html(status)
        ;
    });

}
function updateProductItem(responseData)
{
    var idProduct = responseData.id_product;
    var idProductAttribute = responseData.id_product_attribute;
    var productsInCart = responseData.products_in_cart;
    var qty = responseData.qty;
    var totalProductInCart = 0;


    var productInCart = $.grep(productsInCart,function(p)
    {
        //return p.id_product == idProduct;
        if( p.id_product == idProduct) {
            totalProductInCart = totalProductInCart+p.quantity;
            return p;
        }
    });

    var $productItem = $('article.js-product-miniature[data-id-product="'+idProduct+'"]');
    var $attributeItems = $productItem.find('.am-js-attribute-item[data-id-product-attribute="'+idProductAttribute+'"]');

    if(!(productInCart.length > 0)) {
        $productItem.removeClass('item-selected');
        $.each($attributeItems, function(index, item)
        {
            $(item).data('quantity-attribute', 0);
            $(item).data('id-product-attribute', null);
            if($(item).prop('tagName').toLowerCase() === 'option')
                $(item).text($(item).attr('title'));
        });
    }
    else {
        $.each($attributeItems, function(index, item)
        {
            $(item).data('quantity-attribute',qty);
            $(item).data('id-product-attribute', idProductAttribute);
            // $(item).attr('data-quantity-attribute',0);
            // $(item).attr('data-id-product-attribute', null);
            if($(item).prop('tagName').toLowerCase() === 'option')
                $(item).text($(item).attr('title')+'('+qty+')');
        });
    }
    
}

function updateAllProductsInCart(productsQuantity)
{
    if(productsQuantity.length >0)
        $('.js-checkout__preview a').removeClass('is-disabled');
    else
        $('.js-checkout__preview a').addClass('is-disabled');


    $.each($('.am-js-attribute-item'), function(i, attr)
    {
        $(attr).data('quantity-attribute', 0);
        $(attr).data('id-product-attribute', null);
        $(attr).attr('data-quantity-attribute', 0);
        $(attr).attr('data-id-product-attribute', null);

        //$(attr).closest('article.js-product-miniature').removeClass('item-select');
    });

    $.each($('.js-product-miniature'), function(index, productItem)
    {
        //var $productItem = $('article.js-product-miniature[data-id-product="'+idProduct+'"]');
        var $productItem = $(productItem);
        var idProduct = $productItem.data('id-product');
        //idProductAttribute = $productItem.data('id-product-item');
        //qty = product.cart_quantity;

        var $arrParamsToSearch = [{id_product: idProduct}];
        var productInCartKey = searchInArrayObjectsMultiple($arrParamsToSearch, productsQuantity);

        if(-1 != productInCartKey) {
            $productItem.addClass('item-selected');
            var respAttributesQty = productsQuantity[productInCartKey]['attributes_qty'];
            var respProductAttributes = productsQuantity[productInCartKey]['product_attributes'];

            $.each(respProductAttributes, function(i, arrProductAttribute)
            {
                var idProductAttribute = arrProductAttribute.id_product_attribute;
                var qtyProductAttribute = arrProductAttribute.qty;
                var arrAttributes = arrProductAttribute.attributes;

                $productItem.data('id-product-attribute', idProductAttribute);

                $.each(arrAttributes, function( iAttr, idAttr)
                {
                    var $itemAttribute =  $productItem.find('.am-js-attribute-item[value="'+idAttr+'"]');
                    var attributeInProductKey = $.inArray(idAttr, arrProductAttribute.attributes);

                    if(-1 != attributeInProductKey) {
                        qtyProductAttribute = parseInt(qtyProductAttribute) + parseInt($itemAttribute.data('quantity-attribute'));
                        $itemAttribute.data('quantity-attribute', qtyProductAttribute);
                        $itemAttribute.attr('data-quantity-attribute', qtyProductAttribute);
                        if($itemAttribute.prop('tagName').toLowerCase() === 'option')
                            $itemAttribute.text($itemAttribute.attr('title')+' ('+qtyProductAttribute+')');

                        var $attributeGroup = $itemAttribute.closest('.am-js-attribute-group');
                        $attributeGroup.data('id-product-attribute', idProductAttribute);
                    }
                    else{
                        $itemAttribute.data('quantity-attribute', 0);
                        if($itemAttribute.prop('tagName').toLowerCase() === 'option')
                            $itemAttribute.text($itemAttribute.attr('title'));

                        var $attributeGroup = $itemAttribute.closest('.am-js-attribute-group');
                        $attributeGroup.data('id-product-attribute', null);
                    }
                });
            });
        }
        else {
            $productItem.removeClass('item-select');
            var $attributeItems = $productItem.find('.am-js-attribute-item');

            $.each($attributeItems, function(indexAttr, itemAttribute)
            {
                var $itemAttribute = $(itemAttribute);
                $itemAttribute.data('quantity-attribute', 0);
                if($itemAttribute.prop('tagName').toLowerCase() === 'option')
                    $itemAttribute.text($itemAttribute.attr('title'));
            });
        }
    });
}


function AddToCartSize($button)
{

    var $itemProduct = $button.closest('.js-product-miniature');
    var $addToCartData = $button.closest('.am-addToCartData');
    var url= $button.parents('.am-js-attribute-group').data('url');
    var idProduct =$button.data('product-id');
    var numberAttributeGroups = $itemProduct.find('.am-js-attribute-group').length;
    var attributes = getProductAttributesSelected($itemProduct, false);
    var idProductAttribute =$button.data('id-product-attr');
    var quantityDirection = '';
    var dataToSend = {};

    if( (numberAttributeGroups>0) && (!idProductAttribute > 0))
        return false;

    if ($button.hasClass('js-am-qty-increase'))
        quantityDirection = 'up';
    else  if ($button.hasClass('js-am-qty-decrease'))
        quantityDirection = 'down';

    if(quantityDirection != '') {
        dataToSend = {
            ajax:1,
            add:1,
            action:'update',
            id_product: idProduct,
            id_product_attribute: idProductAttribute,
            op: quantityDirection
        };
    }
    else {
        dataToSend = {
            ajax:1,
            add:1,
            action:'update',
            id_product: idProduct,
            id_product_attribute: idProductAttribute
            //group: attributes,
            //addToCartToken_: staticToken
        };
    }

    var addToCart = $.ajax({
        url: url,
        type: 'POST',
        //dataType: 'ajax',
        data: dataToSend
    });

    addToCart.done(function(resp)
    {
        resp = $.parseJSON(resp);
        if(resp.success){
            amDisplayAddToCartButton($itemProduct, resp);
            prestashop.emit('updateCart', {
                reason: {
                    idProduct: resp.id_product,
                    idProductAttribute: resp.id_product_attribute,
                    linkAction: 'add-to-cart',
                    cart: resp.cart
                },
                resp: resp
            });
        }
        else if (resp.hasError) {
            txtErrors = '';
            $.each(resp.errors, function(index, value) {
                txtErrors += "\n"+value;
            });

            if(txtErrors != '')
                $itemProduct.find('.js-am-variants-notifications')
                    .removeClass('alert-danger alert-success alert-info')
                    .addClass('alert-danger')
                    .html(txtErrors)
                ;
        }
        else{
            // console.log('else void');
        }
    });

    addToCart.fail(function(xhr, status)
    {
        prestashop.emit('handleError', {eventType: 'updateShoppingCart', resp: xhr});

        $itemProduct.find('.js-am-variants-notifications')
            .removeClass('alert-danger alert-success alert-info')
            .addClass('alert-danger')
            .html(status)
        ;
    });

}

function recalculateProductsAttributes(idProduct,colorAttirbute){

    var url= $('.am-js-attribute-group').data('url');
    var productSearch = "js-size-attr-"+idProduct;

    var arraySizes  = Array();

    $("."+productSearch+"").each(function(index, element) {

        arraySizes.push($(element).data('attribute-id'));

    });




    dataToSend = {
        ajax:1,
        add:1,
        action:'changeAttr',
        id_product: idProduct,
        id_product_color: colorAttirbute,
        product_sizes: arraySizes
        //group: attributes,
        //addToCartToken_: staticToken
    };

    var changeAttr = $.ajax({
        url: url,
        type: 'POST',
        data: dataToSend
    });


    changeAttr.done(function(resp)
    {


        if(resp.attributes){

            resp.attributes.forEach(function(element) {



                var name ="js-size-prod-"+idProduct+"-attr-"+element['id-size'];
                $("."+name+"").val(element['attrID']);
                idElement = element['attrID'];
                $("."+name+"").data('id-product-attr',idElement);



            });



        }
        else if (resp.hasError) {
            txtErrors = '';
        }
        else{
            // console.log('else void');
        }
    });

    changeAttr.fail(function(xhr, status)
    {

    });
}

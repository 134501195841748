$(document).ready(function()
{
    // Scroll after first question
    $(window).scroll(function () {
        var windowHeight = $(window).scrollTop();

        var $containerFaqs = $('.js-am-sidebar-content');
        var $firstFaq = $containerFaqs.children().first();

        if($firstFaq.length)  // Hay que comprobar que exista el nodo, sino al hacer un offset de algo que no exite peta el javascript y por tanto la web
        {
            var firstFaq = $firstFaq.offset();
            firstFaq = firstFaq.top;

            if($(window).width() > 768) {
                if (windowHeight >= firstFaq - 50)
                    $('body').addClass('question-links-fixed');
                else
                    $('body').removeClass('question-links-fixed');
            }
        }

        if($(window).width() > 768) {
            $containerFaqs.children().each(function () {
                var faqOffset = $(this).offset();
                if (faqOffset) {
                    var idContent = $(this).attr('id');
                    var topOffset = faqOffset.top;
                    if (windowHeight >= topOffset - $(window).height() / 4) {
                        $('.js-am-sidebar-link').parent('.active').removeClass('active');
                        $('.js-am-sidebar-link[href="#' + idContent + '"]').parent().addClass('active');
                    }
                }
            });
        }
    });

    $('.question-links__list').children().each(function(){
        if($(this).children().first().attr('href') == window.location.hash)
        {
            $(this).addClass('active');

            if($(window).width() < 768){
                $('body').addClass('open-question');
                $(window.location.hash).addClass('active');
                $(window.location.hash).parent().addClass('open');
            }
        }
    });

    $('.question-meta__close').on('click',function () {
        $('body').removeClass('open-question');
        $(this).parent().removeClass('active');
        $(this).parent().parent().removeClass('open');
    });

    /*** metodo deslizamiento faqs sidebar */
    $('.js-am-sidebar-link').on('click', function(evt)
    {
        evt.preventDefault();
        if($(window).width() < 768){
            $('body').removeClass('open-question');
        }
        $('.question-links__list').children().each(function() {
            $(this).removeClass('active');
            if($(window).width() < 768){
                var idContent = $(this).children().attr('href');
                if($(idContent).length > 0)
                {
                    $(idContent).removeClass('active');
                    $(idContent).parent().addClass('open');
                }
            }
        });

        $(this).parent().addClass('active');

        var hashName = $(this).attr('href');

        if($(window).width() < 768){

            $('body').addClass('open-question');
            $(hashName).addClass('active');
            $(hashName).parent().addClass('open');
        }

        $('html, body').animate({
            scrollTop: $(hashName).offset().top - $(window).height()/6
        }, 500);
        window.location.hash = hashName;
    });

    $('.footer__link').click(function(){
        window.location.reload();
    });

    $(window).on('hashchange',function(){

        if($('.sidebar-content').length > 0)
        {

            event.preventDefault();

            var location = $(window.location.hash).offset();

            $('.question-links__item').removeClass('active');

            if(typeof location == "undefined" || location == null)
            {

            }
            else
            {
                $('.js-am-sidebar-link').each(function(){

                    $(this).parent('.question-links__item').removeClass('active');

                    if($(this).attr('href') == window.location.hash)
                    {

                        $(this).parent('.question-links__item').addClass('active');

                    }

                });

                $(window.location.hash).addClass('active');
                $(window.location.hash).parent().addClass('active');

                $('html, body').animate({
                    scrollTop: $(window.location.hash).offset().top - 130
                }, 500);
            }

        }
    });

});

/* ---PRELOADER--- */

import $ from 'jquery'

window.$ = window.jQuery = $;

$(function(){

});

import 'slick-carousel'
import './modules/am_addToCart';
import './modules/am_shoppingcart';
import './modules/front-am-faqs';
import './modules/am_quickly_wishlist';
import './modules/contact-form-enhanced';

import initScroll from "./modules/scrollTo";
import initCheckout from './modules/checkout';

import './theme';

var preloaderDelay = 500,
    preloaderFadeOutTime = 800;
function hidePreloader() {
    var loadingAnimation = $('.preload_dots'),
        preloader = $('.js_preload');
    loadingAnimation.fadeOut();
    preloader.delay(preloaderDelay).fadeOut(preloaderFadeOutTime);
}
hidePreloader();

$(document).ready(function(){
    initScroll();
    initCheckout();
});

document.addEventListener("DOMContentLoaded", function() {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.srcset = lazyImage.src;
                    lazyImage.classList.remove("lazy");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Possibly fall back to a more compatible method here
    }

});



$(document).ready(function ($) {
    if( $('.js-product-slide').length ) {
        $('.js-product-slide').slick({
            draggable: true,
            infinte: true,
            slidesToShow: 3,
            slidesToScroll: 1,

            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]

        });
    }

    if( $('.js-product-slide--four').length ) {
        $('.js-product-slide--four').slick({
            draggable: true,
            infinte: true,
            slidesToShow: 4,
            slidesToScroll: 1,

            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]

        });
    }

    //menu superior mas pequeño al hacer scroll
    $(window).scroll(function() {
        if ($(this).scrollTop() > 80) {
            $('.header').addClass('minimal');
            $('body').addClass('min');

        } else {
            $('.header').removeClass('minimal');
            $('body').removeClass('min');

        }
    });

    // mostar elementos en el mobile
    var $tamano = screen.width;
    if ($tamano < 992){
        $('.js-dropdown').click(function() {
            $('.js-top-menu').toggle('slow');
        });


        //que se muestre el menu del móvil
        $('.js_menu_icon').click(function(){
            $('.header').toggleClass('is-open');
            $('.js_mobile_top_menu_wrapper').toggleClass('open_menu');
            $('.js-top-menu').delay( 400 ).show(50);
        });

        // menu añadir al carrito mobile
        $('.js-open-cart-file').click(function(){
            $('.header').toggleClass('is-open');
            $('.js-file-modal__mobile').toggleClass('open_add');
        });

        // elminar el boton del carrito al hacer scroll
        $(window).scroll(function(){
            var windowHeight = $(window).scrollTop();
            if($('.am-block-BlockAmFaqs').length > 0) {
                var contenido2 = $('.am-block-BlockAmFaqs').offset();
                contenido2 = contenido2.top;
                if(windowHeight >= contenido2  ){
                    $('.js-open-cart-file').fadeOut(500);
                }else{
                    $('.js-open-cart-file').fadeIn(500);
                }
            }
        });
    }


    //que se muestre el menu del móvil
    $('.js_mobile_user_info').click(function(){
        $('.header').toggleClass('is-open');
        $('.user-info').toggleClass('is-open');
    });
    $('body').on('click','.js_read-more__category',function (event) {
        $('.js_read-more__category').toggle('slow');
        $('#category-description').toggle('slow');
    })

    $('body').on('click','.js-category-square',function (event) {
        $(this).addClass('currency');
        $('.js-category-list').removeClass('currency');
        $('#js-product-list').removeClass('grid-list');
    })

    $('body').on('click','.js-category-list',function (event) {
        $(this).addClass('currency');
        $('.js-category-square').removeClass('currency');
        $('#js-product-list').addClass('grid-list');
    })

    $('.delivery-option').click(function() {
        $('.delivery-option').removeClass('selected');
        $(this).addClass('selected');
    });

    $('#close-product-modal').click(function() {
        $('.js-product-images-modal').removeClass('in');
        $('.js-product-images-modal').style('display: none;');
        $('#product').removeClass('modal-open');
    });

    $('.js-select-color-variants').each(function() {
        this.previousElementSibling.innerText = $(this).find(' > li > label > input').first().attr('attribute-name')
    });


    // Contact form - Recaptcha
    $("#contact-form-button").click(function(event){
        event.preventDefault();

        grecaptcha.ready(function() {
            // do request for recaptcha token
            // response is promise with passed token
            grecaptcha.execute($('#recaptcha-api-key').val(), {action: 'contact'})
                .then(function(token) {
                    // add token to form
                    $('#contact-form').prepend('<input type="hidden" id="g-recaptcha-response-contact" name="g-recaptcha-response" value="' + token + '">');
                    $('#contact-form-submit').click();
                }, function ( e ) {
                    $('#alert-contact').show();
                });

        });
    });

    // Contact form - Recaptcha
    $("#newsletter-form-button").click(function(event){
        event.preventDefault();

        grecaptcha.ready(function() {
            // do request for recaptcha token
            // response is promise with passed token
            grecaptcha.execute($('#recaptcha-api-key-newsletter').val(), {action: 'newsletter'})
                .then(function(token) {
                    // add token to form
                    $('#block_newsletter_content_form').prepend('<input type="hidden" id="g-recaptcha-response-newsletter" name="g-recaptcha-response-newsletter" value="' + token + '">');
                    $('#newsletter-form-submit').click();
                });

        });
    });

    initProductGalerySlider();
});

prestashop.on('updatedProduct', function() {
  initProductGalerySlider(true);
});


function initProductGalerySlider(reinitModal)
{
    if( $('.js-product-slide--one').length ) {
        $('.js-product-slide--one').slick({
            draggable: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            dotsClass: 'am-total-slider'
        });
    }

    if( $('.js-product-slide--one').length ) {
        $('.js-product-cover-slide--one').slick({
            draggable: true,
            infinite: false, // should be true but there is a bug
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            dotsClass: 'am-total-slider',
            asNavFor: '.js-product-modal-slide--one',
            lazyLoad: 'ondemand',
            afterChange: function(event, slick, currentSlide, nextSlide){
                console.log(nextSlide);
            }
        });
    }

    $('.layer').on('click',function (){

        $('.js-product-modal-slide--one').css('visibility','hidden');
        let numer = $(this).data('iteration');
        $('.js-product-modal-slide--one').slick('slickGoTo',numer,true);
        $('.js-product-modal-slide--one').slick('refresh');

        setTimeout(function () {
            $('.js-product-modal-slide--one').css('visibility','visible');
        }, 500);

    })

    if( $('.js-product-cover-slide--one').length ) {
        $('.js-product-cover-slide--one').slick({
            draggable: true,
            infinite: false, // should be true but there is a bug
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            dotsClass: 'am-total-slider',
            asNavFor: '.js-product-modal-slide--one',
            lazyLoad: 'ondemand',
        });
    }

    setTimeout(function () {
        if( $('.js-product-modal-slide--one').length ) {
            $('.js-product-modal-slide--one').slick({
                draggable: true,
                infinite: false, // should be true but there is a bug
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                variableWidth: true,
                asNavFor: '.js-product-cover-slide--one',
                lazyLoad: 'ondemand',
            });
        }
    },500);

}

/* global $, prestashop */

$(function ()
{
    const $cartModal = $('.js-shopping-cart-preview');
    const $buttonOpenCart = $('.js-cart-link');

//CLICK FUERA
    $(document).mouseup(function (e) {
        if (!$cartModal.is(e.target) && $cartModal.has(e.target).length === 0)
        {
            $cartModal.addClass('is-disabled');
            $('body').removeClass('open-cart-preview');
        }
    });


    $('#header').on('click', '#js-cart-link', function(event)
    {
        event.preventDefault();
        if($('.js-shopping-cart-preview').hasClass('is-disabled')) {

            $('.js-shopping-cart-preview').removeClass('is-disabled');
            $('body').addClass('open-cart-preview');


        } else {
            $('.js-shopping-cart-preview').addClass('is-disabled');
            $('body').removeClass('open-cart-preview');

        }
    });

    $('aside.shopping-cart-preview').on('click', '.js-close-shopping-cart', function()
    {
        $('body').removeClass('open-cart-preview');
        $('.js-shopping-cart-preview').addClass('is-disabled');
    });

    //OVERLAY CONTROLS

    $('body').on('click', '.overlay-default', function(event)
    {
        if($('body').hasClass('open-aside')){
            $('body').removeClass('open-aside');
            $('body').removeClass('open-cart-preview');
        }
    });


    prestashop.blockcart = prestashop.blockcart || {};

    var showModal = prestashop.blockcart.showModal || function (modal) {
        var $body = $('body');
        $body.append(modal);
        $body.one('click', '#blockcart-modal', function (event) {
            if (event.target.id === 'blockcart-modal') {
                $(event.target).remove();
            }
        });
    };

    prestashop.on('updateCart', function (event) {
        var showModal = prestashop.blockcart.showModal || function (modal)
        {
            var $body = $('body');
            $body.append(modal);
            $body.one('click', '#blockcart-modal', function (event)
            {
                if (event.target.id === 'blockcart-modal')
                    $(event.target).remove();

            });
        };

        var refreshURL = $('.blockcart').data('refresh-url');
        var requestData = {};

        if (event && event.reason) {
            requestData = {
                id_product_attribute: event.reason.idProductAttribute,
                id_product: event.reason.idProduct,
                action: event.reason.linkAction,
                ajax:1
                //id_Attribute: event.reason.idAttribute
            };
        }



        var updateShoppingCart = $.ajax({
            url: refreshURL,
            data: requestData,
            type:'POST',
            success: function (resp) {

            }
        });

        updateShoppingCart.done(function (resp) {
            var blockcart = $(resp.preview).find('.blockcart');
            $('.blockcart').replaceWith(blockcart);

            //cambiamos contenido del carrito modal y total
            if (resp.cartBlock){
                $('.js-block-cart-content').html(resp.cartBlock);
                $('.js-block-cart-quantity').html(resp.total_products);
                $('.am-ajax_block_cart_total').html(resp.cartTotal);


                if(!resp.ordersFree){
                    $('.js-delivery-free').html(resp.spendForFree);
                    if(!$('.js-shipping-minimum').hasClass('is-active')){

                        $('.js-shipping-cost-free').removeClass('is-active');
                        $('.js-shipping-minimum').addClass('is-active');

                    }
                }else{


                    if(!$('.js-shipping-cost-free').hasClass('is-active')){
                        $('.js-shipping-minimum').removeClass('is-active');
                        $('.js-shipping-cost-free').addClass('is-active');
                    }

                }
                if(resp.extra_price  < 1)
                {
                    if($('.js-extra-container').hasClass('is-active'))
                    {

                        $('.js-extra-container').removeClass('is-active');

                    }
                }
                else
                {
                    if(!$('.js-extra-container').hasClass('is-active'))
                    {
                        $('.js-extra-container').addClass('is-active');
                    }

                    $('.js-extra-shipping').html(resp.extra_price+" €");
                }



            }

            //cambiamos cantidad en listado de productos
            if(resp.productsQuantity) {
                try {

                    prestashop.emit('updateAllProducts',{
                        reason: {
                            productsQuantity: resp.productsQuantity
                        }
                    });
                } catch ( e ){

                }
                //updateProductsListQty(resp.productsQuantity);
            }


            //si el response viene con modal lo mostramos
            if (resp.modal != null)
                showModal(resp.modal);
            //si el modal ya existe, se muestra
            else if($('#blockcart-modal').length > 0)
                showModal($('#blockcart-modal') );

            $('body').addClass('show-modal-product');

            setTimeout(function () {
                $('body').addClass('move-modal-product');
            }, 1000);

            setTimeout(function () {
                $('body').addClass('hide-modal-product');
            }, 2000);

            setTimeout(function () {

                $('body').removeClass('move-modal-product');
                $('body').removeClass('show-modal-product');
                $('body').removeClass('hide-modal-product');
                $('.modal-backdrop').remove();
                $('.modal-product-added').remove();
                $('body').removeClass('modal-open');
            }, 3000);
            /*** fin cambio animacion */

        });

        updateShoppingCart.fail(function (jqXHR, textStatus) {
            prestashop.emit('handleError', {eventType: 'updateShoppingCart', resp: textStatus});
        });
    });

    //UP

    //up listado
    $(document).on('click','.cart-quantity .bootstrap-touchspin-up', function(event)
    {
        // console.log('is default up-> '+$(this).closest('.cart-quantity-inline').data('is-default') );
        var $productItem = $(this).closest('article.product-list-item');
        var idProduct = $productItem.data('id-product');
        /*** buscamos si hay attributos */
        var idProductAttribute =0;
        if($productItem.find('.product-variants-item .am_js_group_attribute').length==1){
            var $attrSelector = $productItem.find('.product-variants-item .am_js_group_attribute');
            var $attrSelected = $attrSelector.find('option:selected');
            var idAttribute = $attrSelected.val();
            var idProductAttribute = $attrSelected.attr('data-id-product-attribute');
            var attrProductQty = $attrSelected.attr('data-quantity-attribute');

        };
        /** fin cambio */


        //refreshProductModalHtml($(this).closest('.cart-quantity-inline').data("id-product"));
        refreshProductModalHtml($productItem);

        if($(this).closest('.cart-quantity-inline').data('is-default')){

            var $itemAsideCart = $('aside.js-shopping-cart-preview dt[data-id-product="'+idProduct+'"][data-id-attribute="'+idProductAttribute+'"] ');


            //$('aside.cart-side').find('button.js-increase-product-quantity[data-id-product="'+idProduct+'"]').trigger('click');
            //$itemAsideCart.find('button.js-increase-product-quantity[data-id-product="'+idProduct+'"]').trigger('click');
        }

    });

    $('#checkout').on('click', '.cart-summary__quantity .js-increase-product-quantity', function(event)
    {
        event.preventDefault();
        var $cartItem = $(this).closest('dt.js-product-cart');
        var idProductAttribute = $cartItem.data('id-attribute');

        var $inputData = $(this).parent().find('.js-cart-line-product-quantity');
        var urlUp = $inputData.data('up-url')+'&ajax=1';
        var productUp = $.ajax({
            url: urlUp,
            data: {id_selected_product_attribute: idProductAttribute, action: 'update' } ,
            type: 'GET',
            dataType: 'json'
        });
        productUp.done(function(response)
        {
            try {
                prestashop.emit('updateCart',{
                    reason: response
                });
            } catch ( e ){
                console.log(e);
            }
        });

        productUp.fail(function(jXHR, text)
        {
            console.log('error product down');
        });


    });

    //up carrito
    $('.js-shopping-cart-preview').on('click', '.js-increase-product-quantity', function(event)
    {


        event.preventDefault();
        var $cartItem = $(this).closest('dt.js-product-cart');
        var idProductAttribute = $cartItem.data('id-attribute');

        var $inputData = $(this).parent().find('.js-cart-line-product-quantity');
        var urlUp = $inputData.data('up-url')+'&ajax=1';
        var productUp = $.ajax({
            url: urlUp,
            data: {id_selected_product_attribute: idProductAttribute, action: 'update' } ,
            type: 'GET',
            dataType: 'json',
            success: function(resp) {
                if(resp.success) {

                }
            },
            error: function() {

            }
        });

        productUp.done(function(response)
        {
            try {
                prestashop.emit('updateCart',{
                    reason: response,
                    idProduct: response.id_product,
                    idProductAttribute: response.id_product_attribute,
                    linkAction: 'add-to-cart',
                    cart: response.cart,


                });

            } catch ( e ){
                console.log(e);
            }
        });

        productUp.fail(function(jXHR, text)
        {
            console.log('error product down');
        });
    });

    //up en ficha producto

    $(document).on('click','.product-info__add-quantity .bootstrap-touchspin-up', function(event)
    {
        var currentNbProducts = parseInt($('#quantity_wanted').val())-1;

        if($('.am-quantity-discount-offer').length>0){
            var buyX = parseInt($('.am-quantity-discount-offer').data('buy-x'));
            var newQuantity = parseInt(currentNbProducts + buyX);
            $('#quantity_wanted').val(newQuantity).change();
        }

    });
    //FIN UP


    // DOWN

    //down listado
    $(document).on('click','.cart-quantity .bootstrap-touchspin-down', function(event)
    {
        // console.log('is default down-> '+$(this).closest('.cart-quantity-inline').data('is-default') );
        var $productItem = $(this).closest('article.product-list-item');
        var idProduct = $productItem.data('id-product');

        var quantityProduct = $productItem.find('span.quantity').html();


        //refreshProductModalHtml($(this).closest('.cart-quantity-inline').data("id-product"));
        refreshProductModalHtml($productItem);

        if($(this).closest('.cart-quantity-inline').data('is-default')){
            /*
            if(offer2x1){
                console.log('oferta2x1');
                $productItem.find('div.addToCart-button .cart-quantity-inline:first').remove();
                $productItem.find('.addToCart-button .defaultAddToCartButton form.addToCartForm')
                    .first()
                    .clone()
                    .prependTo( $productItem.find('.addToCart-button').first())
                    .show();
                $productItem.find('.addToCart-button .defaultQuantityButton').hide();
                $productItem.removeClass('product-selected');
                console.log('remove prod-select 2x1');
            }*/
            //  $('aside.cart-side').find('button.js-decrease-product-quantity[data-id-product="'+idProduct+'"]').trigger('click');
        }
        else{
            //comprobamos si es oferta 2x1
            var offer2x1 = false;
            if(($productItem.find('.am-quantity-discount-offer-show').data('offer')=='2x1') && ('2'== quantityProduct)){
                offer2x1 = true;
            }

            if((quantityProduct == '1') || offer2x1 ){
                $productItem.find('div.addToCart-button .cart-quantity-inline:first').remove();
                $productItem.find('.addToCart-button .defaultAddToCartButton form.addToCartForm')
                    .first()
                    .clone()
                    .prependTo( $productItem.find('.addToCart-button').first())
                    .show();
                $productItem.find('.addToCart-button .defaultQuantityButton').hide();
                $productItem.removeClass('product-selected');
            }
        }
    });

    //down carrito
    $('.js-shopping-cart-preview').on('click', '.js-decrease-product-quantity', function(event)
    {

        event.preventDefault();
        var $cartItem = $(this).closest('dt.js-product-cart');
        var idProductAttribute = $cartItem.data('id-attribute');
        var $inputData = $(this).parent().find('.js-cart-line-product-quantity');
        var urlDown = $inputData.data('down-url')+'&ajax=1';

        var productDown = $.ajax({
            url: urlDown,
            type: 'GET',
            dataType: 'json',
            data: {id_selected_product_attribute: idProductAttribute, action: 'update' } ,
        });

        productDown.done(function(response){
            try {
                prestashop.emit('updateCart',{
                    reason: response
                });
            } catch ( e ){
                console.log(e);
            }

        });

        productDown.fail(function(jXHR, text)
        {
            console.log('error product down');
        });


    });

    //down en ficha producto

    $(document).on('click','.product-info__add-quantity .bootstrap-touchspin-down', function(event)
    {
        var currentNbProducts = parseInt($('#quantity_wanted').val() )+1;

        if($('.am-quantity-discount-offer').length>0){
            var buyX = parseInt($('.am-quantity-discount-offer').data('buy-x'));
            var newQuantity = parseInt(currentNbProducts - buyX);
            if(newQuantity<=0) newQuantity = buyX;
            $('#quantity_wanted').val(newQuantity).change();
        }

    });
    //FIN DOWN


    $('.js-shopping-cart-preview').on('click', '.js-cart-remove-product', function(event)
    {
        event.preventDefault();
        var $cartItem = $(this).closest('dt.js-product-cart');
        var idProductAttribute = $cartItem.data('id-attribute');
        var idProduct = $cartItem.data('id-product');
        var urlAction = $(this).data('url');

        var deleteToCart = $.ajax({
            url: urlAction,
            type:'GET',
            data: {action: 'update'}
        });

        deleteToCart.done(function(response){
            prestashop.emit('updateCart',{
                reason: JSON.parse(response)
            });
            //$nodeHtml.remove();
            var $productItem = $('article.js-product-miniature[data-id-product="'+idProduct+'"]');
            $productItem.removeClass('item-selected');
        });

        deleteToCart.fail(function(jqXHR, textStatus){

        });


    });

});

//versionamos fnV1 pero recorriendo directamente peoductsQuantity y buscando sus datos en cada productitem y attribute ggroup
function updateProductsListQty(productsQuantity)
{
    $('.js-product-miniature').removeClass('item-selected');
    $.each(productsQuantity, function(index, productInCart) {
        var idProduct = productInCart.idProduct;
        //var idAttribute = productInCart.idAttribute;
        //var productQty = productInCart.quantity;
        var idProductAttribute = productInCart.idProductAttribute;

        var $productItem = $('article.js-product-miniature[data-id-product="' + idProduct + '"]');

        $productItem.addClass('item-selected');

        $.each(productInCart.quantityAttributes, function(index, attributeQuantity)
        {
            var idAttribute = attributeQuantity.idAttribute;
            var qty = attributeQuantity.quantity;
            var $itemAttribute = $productItem.find('.am-js-attribute-item[value="' + idAttribute + '"]');
            var itemTitle = $itemAttribute.attr('title');
            //otra opcion
            // var $itemAttribute = $('.am-js-attribute-item').filter(function()
            // {
            //     return this.value == idAttribute;
            // });

            var $attributeSelector = $itemAttribute.closest('.am-js-attribute-group');

            if (qty > 0) {
                $productItem.addClass('item-selected');
                $itemAttribute.data('quantity-attribute', qty);
                $itemAttribute.attr('data-quantity-attribute', qty);
                if ($itemAttribute.prop('tagName').toLowerCase() === 'option') {
                    $itemAttribute.text(itemTitle + ' (' + qty + ')');
                }
            }
            else {
                $productItem.removeClass('item-selected');
                $itemAttribute.data('quantity-attribute', 0);
                $itemAttribute.attr('data-quantity-attribute', 0);
                if ($itemAttribute.prop('tagName').toLowerCase() === 'option') {
                    $itemAttribute.text(itemTitle);
                }
            }

        });
    });
}

export default function () {
    if ($("#checkout-personal-information-step").hasClass("-complete")) {
        $(".js-nav-data").addClass("completed");
    }

    if ($("#checkout-addresses-step").hasClass("-complete")) {
        $(".js-nav-address").addClass("completed");
    }

    if ($("#checkout-delivery-step").hasClass("-complete")) {
        $(".js-nav-delivery").addClass("completed");
    }
    if ($("#checkout-payment-step").hasClass("-complete")) {
        $(".js-nav-payment").addClass("completed");
    }

    if($("#checkout-personal-information-step").hasClass("js-current-step")){
        $(".js-nav-data").addClass("current");
    }
    if($("#checkout-addresses-step").hasClass("js-current-step")){
        $(".js-nav-address").addClass("current");
    }

    if($("#checkout-delivery-step").hasClass("js-current-step")){
        $(".js-nav-delivery").addClass("current");
    }

    if($("#checkout-payment-step").hasClass("js-current-step")){
        $(".js-nav-payment").addClass("current");
    }


    $('body').on('click', '.js-nav-checkout', function(event){

        if(!$(this).hasClass('current') && !$(this).hasClass('completed')) {
            return true;
        }

        var dataopen = $(this).data('open');
        if (dataopen === undefined) {
            dataopen = $(this).data('first');
        }

        $(".js-current-step").fadeOut(300, function()
        {
            $(".js-current-step").removeClass("js-current-step");
            $("." + dataopen).fadeIn(300, function()
            {
                $("." + dataopen).addClass("js-current-step");
                $("." + dataopen).removeClass("-complete");
            });
        });window.location.hash= $("." + dataopen).data('hash');
    });
}
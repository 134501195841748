

  $(document).ready(function () {

      $('body').on('click','.js-product-miniature .js-button-wishlist', function(event){
        event.preventDefault();
        var ajaxURL = $(this).attr('href');
        var elementThis = $(this);
        var idProduct = $(this).closest('.js-product-miniature').data('id-product');
        var $dataAction = $(this).data('action');
          var requestData = {};
          requestData = {
                      id_product: idProduct,
                        ajax: true,
                        action: $dataAction

                    };
              $.post(ajaxURL, requestData).then(function (resp) {
                  if($('.js-page-wishlist').length > 0){
                      elementThis.closest('.js-product-miniature').remove();

                  }else{
                      var parent = elementThis.closest('.js-display-button');
                      elementThis.closest('.js-display-button').html(resp.blockContent);
                      parent.find('.js-button-wishlist').addClass('is-animated');

                      setTimeout(function(){
                          parent.find('.js-button-wishlist').removeClass('is-animated');
                      }, 2000);
                  }

                  var numberProducts = parseInt(resp.numberOfProducts);

                  if(parseInt(numberProducts) == 0)
                  {
                      $('.js-wishlist-empty').removeClass('hidden');

                      $('.js-wishlist-header').removeClass('nav-wishlist--fill');
                      if(!$('.js-wishlist-header').hasClass('nav-wishlist--empty'))
                          $('.js-wishlist-header').addClass('nav-wishlist--empty');
                  }else
                  {
                      $('.js-wishlist-header').removeClass('nav-wishlist--empty');

                      if(!$('.js-wishlist-header').hasClass('nav-wishlist--fill')){
                          $('.js-wishlist-header').addClass('nav-wishlist--fill');

                      }

                  }



                  $('.js-number-whislist-products').text(resp.numberOfProducts);



              }).fail(function (resp) {

              });

              event.stopPropagation();

      });


      $('body').on('click','.js-display-button-product .js-button-wishlist', function(event){
          event.preventDefault();
          var ajaxURL = $(this).attr('href');
          var elementThis = $(this);
          var idProduct = $('.js-display-button-product').data('product-id');
          var $dataAction = $(this).data('action');
          var requestData = {};
          requestData = {
              id_product: idProduct,
              ajax: true,
              action: $dataAction

          };
          $.post(ajaxURL, requestData).then(function (resp) {
              if($('.js-page-wishlist').length > 0){
                  elementThis.closest('.js-product-miniature').remove();

              }else{
                  elementThis.closest('.js-display-button-product').html(resp.blockContent);
                  var parent =  $('.js-display-button-product');
                  parent.find('.js-button-wishlist').addClass('is-animated');

                  setTimeout(function(){
                      parent.find('.js-button-wishlist').removeClass('is-animated');
                  }, 2000);
              }

              var numberProducts = parseInt(resp.numberOfProducts);

              if(parseInt(numberProducts) == 0)
              {
                  $('.js-wishlist-empty').removeClass('hidden');

                  $('.js-wishlist-header').removeClass('nav-wishlist--fill');
                  if(!$('.js-wishlist-header').hasClass('nav-wishlist--empty'))
                      $('.js-wishlist-header').addClass('nav-wishlist--empty');
              }else
              {
                  $('.js-wishlist-header').removeClass('nav-wishlist--empty');

                  if(!$('.js-wishlist-header').hasClass('nav-wishlist--fill')){
                      $('.js-wishlist-header').addClass('nav-wishlist--fill');
                 
                  }

              }



              $('.js-number-whislist-products').text(resp.numberOfProducts);



          }).fail(function (resp) {

          });

      });
  });

